import { LoaderFunction } from "react-router-dom";

import { certificateAPI } from "../services/certificate.service";
import { store as certificateStore } from "../store";

const certificateLoader: LoaderFunction = async ({ params }) => {
  if (!params?.certificateId) {
    throw new Error("No certificate ID provided");
  }
  const { data } = await certificateStore.dispatch(
    certificateAPI.endpoints.getCertificate.initiate(params.certificateId)
  );

  if (!data) {
    throw new Response("No certificate found", { status: 404 });
  }

  return data;
};

export default certificateLoader;
