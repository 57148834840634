import { CARBON_METHOD_NAMES, CDRMethod } from "../constants";
import { CDRBreakdown } from "../types/cdr-request.type";

export const kgOfMethod = (cdrBreakdown: CDRBreakdown, method: CDRMethod) => {
  return cdrBreakdown[method]?.kg || 0;
};

/**
 * Calculate the total kg of all methods in a CDRRequest but ignores negative kg values
 *
 * @param {CDRBreakdown} cdrBreakdown
 * @return {number} total kg of all methods
 */
export const calculateTotalKg = (cdrBreakdown: CDRBreakdown): number => {
  return Object.values(cdrBreakdown).reduce(
    (sum, method) => (method.kg > 0 ? sum + method.kg : sum),
    0
  );
};

/**
 * Nicely format the given number into a string like "1,000 kg"
 *
 * This should nicely format the total kg of the request into a string like "1,000 kg"
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#options
 *
 * @param {number} kg
 * @return {string} nicely formatted weight
 */
export const formatWeight = (kg: number): string => {
  // Negative weights should end up being 0
  kg = Math.max(0, kg);

  let maximumFractionDigits = 3;
  if (kg > 1) {
    maximumFractionDigits = 2;
  }
  if (kg > 50) {
    maximumFractionDigits = 1;
  }
  if (kg > 100) {
    maximumFractionDigits = 0;
  }
  return new Intl.NumberFormat("en-US", {
    style: "unit",
    unit: "kilogram",
    maximumFractionDigits,
  }).format(kg);
};

/**
 * Format the given weight as a percentage of the total kg
 *
 * @param {number} totalKg
 * @param {number} kg
 * @return {string} formatted weight as percentage
 */
export const formatWeightAsPercentage = (
  totalKg: number,
  kg: number
): string => {
  const percentage = (kg / totalKg) * 100;
  return new Intl.NumberFormat("en-US", {
    style: "unit",
    unit: "percent",
  }).format(percentage);
};

/**
 *
 * @param {CDRBreakdown} cdrBreakdown
 * @param {CDRMethod} method
 * @return {string} formatted weight for method, empty string if method is 0
 */
export const formattedWeightForMethod = (
  cdrBreakdown: CDRBreakdown,
  method: CDRMethod
): string => {
  const details = cdrBreakdown[method];
  if (details) {
    return `${formatWeight(details.kg)} with ${
      CARBON_METHOD_NAMES[method].short
    }`;
  }
  return "";
};
