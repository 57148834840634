import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";

import { certificateAPI } from "./services/certificate.service";

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [certificateAPI.reducerPath]: certificateAPI.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(certificateAPI.middleware),
});

setupListeners(store.dispatch);
