import React, { FC } from "react";

import Error404Image from "../../assets/img/error-404.webp";
import styles from "./Error404.module.css";

// Uses styles and design from the carbon
const Error404: FC = () => {
  return (
    <div className={styles["error-wrapper"]}>
      <div className={styles["error-content-area"]}>
        <div className="flex flex-col justify-center items-center">
          <div className={styles["img-wrapper"]}>
            <img
              src={Error404Image}
              alt="404"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
          <h1>oops!</h1>
          <h2>Page not found</h2>
          <p>
            A 404 error is a common error and can be caused by a number of
            issues. Essentially, a 404 error occurs when the information that
            you requested is not available.
          </p>
        </div>
      </div>
    </div>
  );
};

export { Error404 };
