import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import {
  connectFirestoreEmulator,
  doc,
  getDoc,
  getFirestore,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const privateCertificateDocPath = (certificateId: string) =>
  [FirebaseCollection.CERTIFICATES, certificateId].join("/");
const publicCertificateDocPath = (certificateId: string) =>
  [privateCertificateDocPath(certificateId), "public", certificateId].join("/");

export const enum FirebaseCollection {
  CERTIFICATES = "certificates",
  ONE_TIME = "one-time",
  SUBSCRIPTIONS = "subscriptions",
}

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);

if (process.env.USE_EMULATORS && process.env.NODE_ENV === "development") {
  connectFirestoreEmulator(db, "127.0.0.1", 8080);
}

export const lookupPublicCertificate = (certificateId: string) => {
  const docRef = doc(db, publicCertificateDocPath(certificateId));
  return getDoc(docRef);
};
