import React, { FC } from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

import { Error404 } from "./Error404";

const Error: FC = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    switch (error.status) {
      case 404:
        return <Error404 />;
      default:
        break;
    }
  }
  return <h1>Something went wrong</h1>;
};

export { Error };
