import classnames from "classnames";
import React, { FC } from "react";

import { ReactComponent as Logo } from "../../assets/img/carbon_removed_logo.svg";
import { PublicCertificateDocument } from "../../types/certificate.type";
import { calculateTotalKg, formatWeight } from "../../utils/weight.util";
import AppBreakdownList from "../BreakdownList/BreakdownList";
import styles from "./Certificate.module.css";

type Props = {
  certificate: PublicCertificateDocument;
};

const AppCertificate: FC<Props> = ({ certificate }) => {
  return (
    <div className={styles.certificate}>
      <div
        className={classnames(
          styles["certificate--border"],
          styles["certificate--container"]
        )}
      >
        <Logo width={250} />
        <h1 className={styles["certificate--title"]}>
          Negative Emissions Certificate
        </h1>
        <p className={styles["certificate--weight"]}>
          {formatWeight(calculateTotalKg(certificate.breakdown))}
        </p>
        <p className={styles["certificate--description"]}>
          of CO₂ emission removal was purchased in the name of
        </p>
        <p className={styles["certificate--display-name"]}>
          {certificate.displayName}
        </p>
        <div className={styles["footer-wrapper"]}>
          <AppBreakdownList breakdown={certificate.breakdown} />
          <div className={styles["certificate--footer"]}>
            <p className={styles["certificate--footer--date"]}>
              {certificate.issuedDate.toDate().toLocaleDateString("en-GB")}
            </p>
            <p className={styles["certificate--footer--id"]}>
              {certificate.certificateId}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppCertificate;
