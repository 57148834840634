import React, { FC } from "react";

import { ReactComponent as Biochar } from "../../assets/icons/removal-methods/biochar.svg";
import { ReactComponent as BioOil } from "../../assets/icons/removal-methods/biooil-light.svg";
import { ReactComponent as DACS } from "../../assets/icons/removal-methods/dacs-light.svg";
import { ReactComponent as Forestation } from "../../assets/icons/removal-methods/forestation-light.svg";
import { ReactComponent as Kelp } from "../../assets/icons/removal-methods/kelp-light.svg";
import { ReactComponent as Olivine } from "../../assets/icons/removal-methods/olivine.svg";
import { ReactComponent as Waves } from "../../assets/icons/removal-methods/waves.svg";
import { CARBON_METHOD_NAMES, CDRMethod } from "../../constants";
import styles from "./BreakdownItem.module.css";

type Props = {
  method: CDRMethod;
  methodAmount: string; // Could be '100 kg' or '5%' etc.
};

const AppBreakdownItem: FC<Props> = ({ method, methodAmount }) => {
  let Icon:
    | React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & { title?: string }
      >
    | undefined = undefined;
  switch (method) {
    case CDRMethod.DACS:
      Icon = DACS;
      break;
    case CDRMethod.BIO_OIL:
      Icon = BioOil;
      break;
    case CDRMethod.FORESTATION:
      Icon = Forestation;
      break;
    case CDRMethod.KELP_SINKING:
      Icon = Kelp;
      break;
    case CDRMethod.OLIVINE:
      Icon = Olivine;
      break;
    case CDRMethod.BIOCHAR:
      Icon = Biochar;
      break;
    case CDRMethod.SEAWATER_ELECTROLYSIS:
      Icon = Waves;
      break;
  }

  return (
    <div className={styles["breakdown-card"]}>
      {Icon && (
        <Icon
          className={styles["breakdown-card__icon"]}
          height={50}
          width={50}
        />
      )}
      <p className={styles["breakdown-card__name"]}>
        {methodAmount} {CARBON_METHOD_NAMES[method].short}
      </p>
    </div>
  );
};

export default AppBreakdownItem;
