import React from "react";
import { useLoaderData } from "react-router-dom";

import "./App.css";
import AppCertificate from "./components/certificate/Certificate";
import { PublicCertificateDocument } from "./types/certificate.type";

function App() {
  const certificate: PublicCertificateDocument =
    useLoaderData() as PublicCertificateDocument;

  return <AppCertificate certificate={certificate} />;
}

export default App;
