export const enum CDRMethod {
  FORESTATION = "forestation",
  BIO_OIL = "bio-oil",
  DACS = "dacs",
  OLIVINE = "olivine",
  KELP_SINKING = "kelp-sinking",
  BIOCHAR = "biochar",
  SEAWATER_ELECTROLYSIS = "seawater-electrolysis",
}

export const CARBON_METHOD_NAMES = {
  [CDRMethod.FORESTATION]: {
    long: "Forestation",
    short: "Forest",
  },
  [CDRMethod.BIO_OIL]: {
    long: "Bio-oil",
    short: "Bio-oil",
  },
  [CDRMethod.DACS]: {
    long: "Direct Air Capture and Storage",
    short: "DACS",
  },
  [CDRMethod.OLIVINE]: {
    long: "Olivine Mineralization",
    short: "Olivine",
  },
  [CDRMethod.KELP_SINKING]: {
    long: "Kelp Sinking",
    short: "Kelp",
  },
  [CDRMethod.BIOCHAR]: {
    long: "Biochar",
    short: "Biochar",
  },
  [CDRMethod.SEAWATER_ELECTROLYSIS]: {
    long: "Seawater Electrolysis",
    short: "Seawater",
  },
};
