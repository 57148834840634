import React, { FC } from "react";

import { CDRBreakdown } from "../../types/cdr-request.type";
import {
  calculateTotalKg,
  formatWeightAsPercentage,
} from "../../utils/weight.util";
import AppBreakdownItem from "../BreakdownItem/BreakdownItem";
import styles from "./BreakdownList.module.css";

type Props = {
  breakdown: CDRBreakdown;
};

const AppBreakdownList: FC<Props> = ({ breakdown }) => {
  const totalKg = calculateTotalKg(breakdown);
  return (
    <div className={styles["breakdown-list"]}>
      {(Object.keys(breakdown) as Array<keyof CDRBreakdown>).map((item) => {
        const kg = breakdown[item]?.kg;
        if (kg && kg > 0) {
          return (
            <AppBreakdownItem
              key={item}
              method={item}
              methodAmount={formatWeightAsPercentage(totalKg, kg)}
            />
          );
        }
        return <></>;
      })}
    </div>
  );
};

export default AppBreakdownList;
