import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { FirebaseError } from "firebase/app";

import { lookupPublicCertificate } from "../firebase";
import { PublicCertificateDocument } from "../types/certificate.type";

export const certificateAPI = createApi({
  reducerPath: "certificateAPI",
  baseQuery: fakeBaseQuery(),
  endpoints: (builder) => ({
    getCertificate: builder.query<PublicCertificateDocument, string>({
      queryFn: async (certificateId: string) => {
        try {
          const certificateDoc = await lookupPublicCertificate(
            // Our certificates all use Uppercase IDs
            certificateId.toUpperCase()
          );
          if (!certificateDoc.exists) {
            return { error: "Certificate not found" };
          }
          return { data: certificateDoc.data() as PublicCertificateDocument };
        } catch (err: unknown) {
          console.error(err);
          if (err instanceof FirebaseError) {
            return { error: err.message };
          }
          return { error: err };
        }
      },
    }),
  }),
});

export const { useGetCertificateQuery } = certificateAPI;
